module.exports = {
    /**
   * @type {String}
   * @description 项目名称
   */
    title: ' ㅤ',
    uploadAction: '/api/school/common/uploadFile',
    uploadVideoAction: '/api/school/uploadVideo',
    minIo: 'https://minio.xinkaoyun.com:9000/example/'
}
