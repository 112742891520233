/**
 * 登录页记住密码相关
 * 默认记录7天
*/
import Cookies from "js-cookie";
const DayKey = 'STORE_DAY_TOKEN';
function set7DayToken (projectMark, data) {
    const key = DayKey + projectMark.toLocaleUpperCase();
    const token = data.token.token;
    // let seconds = 10;
    // let expires = new Date(new Date() * 1 + seconds * 1000);
    Cookies.set(key,token, { expires: 7 });
}

function get7DayToken (projectMark) {
    if (projectMark) {
        const key = DayKey + projectMark.toLocaleUpperCase();
        return Cookies.get(key);
    }
}

function remove7DayToken (projectMark) {
    const key = DayKey + projectMark.toLocaleUpperCase();
    Cookies.remove(key)
}

export {
    set7DayToken,
    get7DayToken,
    remove7DayToken
}



