const sessionKey = 'PAGE_LIST_OPTIMAL';
class Event {
    constructor () {
        this.handlers = new Map();
        this.getSubscribe();
    }

    getSubscribe () {
        let _data = sessionStorage.getItem(sessionKey);
        this.handlers = new Map(JSON.parse(_data))
    }

    subscribe (key, args) {
        this.handlers.set(key, args)
        this.setSessionStorage();
    }

    fire (key) {
        return this.handlers.get(key)
    }

    unsubscribe (key) {
        this.handlers.delete(key);
        this.setSessionStorage();
    }

    clearAllData (key) {
        if (key && this.handlers.has(key)) {
            let _keys = [];

            this.handlers.forEach(function (v, k) {
                if (key !== k) {
                    _keys.push(k)
                }
            })

            _keys.map((subKey) => {
                return this.handlers.delete(subKey);
            })

        } else {
            this.handlers.clear();
        }

        this.setSessionStorage();
    }

    setSessionStorage () {
        try {
            sessionStorage.setItem(sessionKey, JSON.stringify(Array.from(this.handlers.entries())));
        }catch(e){}
    }
}

class RecordsList {
    constructor () {
        this.methods = new Event();
    }

    set (key, args) {
        this.methods.subscribe(key, args)
    }

    get (key) {
        return this.methods.fire(key)
    }

    remove (key) {
        this.methods.unsubscribe(key)
    }

    clearAllData (key) {
        this.methods.clearAllData(key);
    }
}

export default RecordsList;
