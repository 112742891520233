import axios from 'axios';
import { getToken } from "./auth";
import store from "@/store";
const qs = require('qs');
/**
 * @Description: 薪资千分制
 * @DoWhat:
 * @UseScenarios:
 * @Attention:
 * @Author: 陈金伟
 * @Date: 2023-04-24 09:15:34
 * @param {*} cellValue
 */
function formatterXZ(cellValue) {
    const formatter = new Intl.NumberFormat("zh-CN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    });
    return formatter.format(cellValue);
}
function formatRouterTree(data) {
    if (!Array.isArray(data)) return;
    let parents = data.filter(p => !p.parentId),
        children = data.filter(c => c.parentId);

    dataToTree(parents, children);

    function dataToTree(parents, children) {
        parents.map((p) => {
            children.map((c, i) => {
                if (c.parentId === p.menuId) {
                    let _c = JSON.parse(JSON.stringify(children));
                    _c.splice(i, 1);
                    dataToTree([c], _c);
                    if (p.children) {
                        p.children.push(c);
                    } else {
                        p.children = [c];
                    }
                }
            })
        })
    }

    return parents;
}

function generateRouter(userRouters) {
    let fileVues = require.context("@/views", false, /\.vue$/);
    let fileVueArr = fileVues.keys()
    let newRouters = userRouters.map((r) => {
        let com = null;
        if (fileVueArr.includes(`./${r.name}.vue`)) {
            com = () => import(/* webpackChunkName: "[request]" */`@/views/${r.name}`)
        } else {
            com = () => import(`@/views/IframeOptionsList`)
        }
        let routes = {
            path: r.path,
            name: r.name,
            meta: {
                title: r.title,
                menuId: r.menuId,
                linkJump: r.linkJump,  // 是否内部跳转
                frameUrl: r.frameUrl,  // 链接地址
                isFrame: r.isFrame   //是否是外链
            },
            component: com
        };

        if (r.children) {
            routes.children = generateRouter(r.children);
        }

        return routes;
    });

    return newRouters;
}

function filterTempInfo(data) {
    let arr = eval(data);
    arr.push(
        {
            id: 1,
            type: '',
            title: '',
            headerColor: '',
            boardColor: '',
            barColor: '',
            sel: '',
            del: ''
        }
    )
    return arr;
}

function filterTemplateBtnInfo(data) {
    return (new Function("return " + data))()
}

function filterTempInfoType(data) {
    let type = '';
    data.forEach((p) => {
        if (p.sel) {
            type = p.type
        }
    });

    return type;
}

function listToTree(data, options) {
    if (data.length == 1) { return data }
    options = options || {};
    var ID_KEY = options.idKey || 'id';
    var PARENT_KEY = options.parentKey || 'parentId';
    var CHILDREN_KEY = options.childrenKey || 'children';

    var tree = [], childrenOf = {};
    var item, id, parentId;

    for (var i = 0, length = data.length; i < length; i++) {
        item = data[i];
        id = item[ID_KEY];
        parentId = item[PARENT_KEY] || 0;
        // every item may have children
        childrenOf[id] = childrenOf[id] || [];
        // init its children
        item[CHILDREN_KEY] = childrenOf[id];
        if (parentId != 0) {
            // init its parent's children object
            childrenOf[parentId] = childrenOf[parentId] || [];
            // push it into its parent's children object
            childrenOf[parentId].push(item);
        } else {
            tree.push(item);
        }
    }
    // if (tree.length == 0) {o(childrenOf)}

    return tree;
}
/**
 * @param {Object} obj
 * @param {Function} cb
 * @param {Function} err
 * @description 下载文件
 */
function downloadFile(obj, cb, err) {
    const {
        url,
        form,
        method = 'post'
    } = obj
    console.log(url, form, method, 'url')
    axios({
        method,
        url: '/api' + url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: getToken()
        },
        data: form,
        // params: form,
        responseType: 'blob',
        withCredentials: true
    }).then((res) => {
        if (!res.data) {
            return
        }
        let iconv = require('iconv-lite')
        let blob = new Blob([res.data])
        let params = url.split('/')
        let temp = ''
        if (params.length > 0) {
            temp = iconv.encode(params[params.length - 1], 'gbk')
        }
        if (res.headers['content-disposition']) {
            temp = res.headers['content-disposition'].split(';')[1].split('filename=')[1]
        }
        if (obj.fileName) {
            temp = iconv.encode(obj.fileName, 'gbk')
        }
        iconv.skipDecodeWarning = true// 忽略警告
        let fileName = iconv.decode(temp, 'gbk')
        if (window.navigator.msSaveBlob) { // IE以及IE内核的浏览器
            try {
                window.navigator.msSaveBlob(blob, fileName) // 只提供一个保存按钮
                // window.navigator.msSaveOrOpenBlob(res, name); //提供保存和打开按钮
            } catch (e) {

            }
        } else {
            let url = window.URL.createObjectURL(blob)
            let aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', fileName)
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink) // 下载完成移除元素
            window.URL.revokeObjectURL(url) // 释放掉blob对象
            cb()
        }
    }).catch(() => {
        err()
    })
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 * @description 获取时间差 超过24小时返回时间戳转换后的日期
 */
function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}
/**
 * @param {string} url
 * @returns {Object}
 * @description 获取url携带参数
 */
function param2Obj(url) {
    let search = url.split('?')[1],
        obj = {};
    if (!search) {
        return {}
    }
    try {
        obj = JSON.parse(decodeURIComponent(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
            .replace(/\+/g, ' '))
    } catch (e) {
        console.log(search.split('&'), '000')
        let newUrl = search.split('&');
        newUrl.forEach((item) => {
            let arr = item.split('=');
            obj[arr[0]] = arr[1];
        })

    }
    console.log(obj, 'obj')
    return obj;
    //   return JSON.parse(
    //     '{"' +
    //     decodeURIComponent(search)
    //       .replace(/"/g, '\\"')
    //       .replace(/&/g, '","')
    //       .replace(/=/g, '":"')
    //       .replace(/\+/g, ' ') +
    //     '"}'
    //   )
}

function equivalentAssignment(ori, tar) {
    var tar = tar || {};

    Object.keys(ori).forEach((key) => {
        if (Object.keys(tar).length > 0) {
            Object.keys(tar).forEach((subKey) => {
                if (key == subKey) {
                    tar[subKey] = ori[key] ? ori[key] : tar[subKey];
                }
                if (tar[subKey] instanceof Array) {
                    tar[subKey] = tar[subKey].join(',')
                }
            })
        } else {
            tar[key] = ori[key];
        }
    })
    return tar;
}

function integratingData(arr) {
    return arr.reduce((prev, item) => {
        const [target = []] = Object.values(item);

        target.forEach((elem, index) => {
            if (!prev[index]) prev[index] = {};

            prev[index] = Object.assign(prev[index], elem)
        });

        return prev;
    }, [])
}

/**
 * @param {Array} data
 * @param {string} key
 * @param {string} parentKey
 * @returns {Array}
 * @description 生成树形数据
 */
function formatTreeData(data, key, parentKey) {
    let parents = []
    let children = []
    data.forEach((item, index) => {
        let find = data.find(i => item[parentKey] === i[key])
        if (find && item[parentKey] !== undefined) {
            children.push(item)
        } else {
            parents.push(item)
        }
    })
    dataToTree(parents, children, key, parentKey)
    function dataToTree(parents, children, key, parentKey) {
        parents.map((p) => {
            children.map((c, i) => {
                if (c[parentKey] === p[key]) {
                    let _c = JSON.parse(JSON.stringify(children))
                    _c.splice(i, 1)
                    dataToTree([c], _c, key, parentKey)
                    if (p.children) {
                        p.children.push(c)
                    } else {
                        p.children = [c]
                    }
                }
            })
        })
    }
    return parents
}

export function formatTimeObj(date, cFormat) {
    if (typeof date === 'object') {
        const format = cFormat || 'y-m-d h:i:s'
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const time_str = format.replace(/([ymdhisa])/g, (result, key) => {
            const value = formatObj[key]
            return value.toString().padStart(2, '0')
        })
        return time_str
    }
    return date
}

/**
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 * @description 时间戳格式化
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || 'y-m-d h:i:s'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/([ymdhisa])/g, (result, key) => {
        const value = formatObj[key]
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        return value.toString().padStart(2, '0')
    })
    return time_str
}

function getSimpleId() {
    sleep(10); //主要为了延时工作
    return new Date().getTime();
}

function sleep(time) {
    const startTime = new Date().getTime() + parseInt(time, 10);
    while (new Date().getTime() < startTime) { }
};

function isLayout(obj) {
    return obj.compType === 'row';
}

/**
 * 树结构数据条件过滤
 * js 指定删除数组(树结构数据)
 */
function treeFilter(data, findKey, findVal, subarrKey) {
    let newData = data.filter(x => x[findKey] !== findVal)
    newData.forEach(x => x[subarrKey] && (x[subarrKey] = treeFilter(x[subarrKey], findKey, findVal, subarrKey)))
    return newData
}

/**
 * 树结构查找数据
 */
function treeFind(tree, func, childKey = 'children') {
    for (const data of tree) {
        if (func(data)) return data
        if (data[childKey]) {
            const res = treeFind(data[childKey], func, childKey)
            if (res) return res
        }
    }
    return null
}

// 树结构深度优先先序遍历(func中操作data参数会修改原树)
function treeForeach(tree, childKey, func) {
    tree.forEach((data, index) => {
        func(data, index)
        data[childKey] && treeForeach(data[childKey], childKey, func) // 遍历子树
    })
}

// 查找节点全部路径
function treeFindPathAll(tree, childKey, func, path = [], result = []) {
    for (const data of tree) {
        path.push(data)
        func(data) && result.push([...path])
        data[childKey] && treeFindPathAll(data[childKey], childKey, func, path, result)
        path.pop()
    }
    return result
}

// 树结构转列表结构递归实现
function treeToList(tree, childKey, result = [], level = 0) {
    tree.forEach(node => {
        result.push(node)
        // node.level = level + 1
        node[childKey] && treeToList(node[childKey], childKey, result, level + 1)
    })
    return result
}


/**
 * 树形搜索
 * @param {Array} tree 过滤数据
 * @param {String} childKey  子级为节点对象的某个属性值
 * @param {Function} func 过滤方法
 * @param {Boolean} showChild  过滤数据是否显示子级
 */
export function treeSearch(tree, childKey, func, showChild = false) {
    // 深拷贝一下节点，避免修改到原树
    let treeCopy = _.cloneDeep(tree);
    return treeCopy.filter((node) => {
        if (showChild && func(node)) {
            return true
        }
        node[childKey] = node[childKey] && treeSearch(node[childKey], childKey, func, showChild);
        return func(node) || (node[childKey] && node[childKey].length);
    });
}
// Object Array根据指定字段去重
function unique(arr, val) {
    const res = new Map()
    return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
}

/**
 * 判断权限
 */
function hasPermission(value) {
    const all_permission = '*:*';
    const permissions = store.state.permissions
    if (value && value instanceof Array && value.length > 0) {
        const permissionFlag = value
        if (permissions.length > 0) {
            const hasPermissions = permissions.some(permission => {
                return all_permission === permission || permissionFlag.includes(permission)
            })
            if (!hasPermissions) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    } else {
        console.log(`请设置操作权限标签值`)
        return true
    }
}

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

/**
 * @Description: 移除body loading 类
 * @DoWhat:
 * @UseScenarios:
 * @Attention:
 * @Author:
 * @Date: 2023-03-16 16:42:36
 */
function removeClassSty() {
    let oPageBoard = document.getElementById('app');
    if (oPageBoard.classList.contains('loading')) {
        oPageBoard.classList.remove('loading');
    }
}

function removeTreeShapeDialog() {
    let treeShapeDialog = document.querySelector('.tree-shape-dialog'),
        bodyBox = document.querySelector('body');
    bodyBox.removeChild(treeShapeDialog);
}

function arrObjDuplication(arrObj) {
    let map = new Map();
    for (let item of arrObj) {
        if (!map.has(item.id)) {
            map.set(item.id, item);
        };
    };
    return [...map.values()];
}

/**
 * 清理树形数据中孩子节点为空数组的情况
 * @param { Array } arr 待处理的树形数组
 * @param { String } children 孩子节点
 * @returns
 */
function cleanTree(arr, childrenKey = 'children') {
    let result = JSON.parse(JSON.stringify(arr));
    for (let i = 0; i < result.length; i++) {
        const node = result[i];
        if (node[childrenKey] === undefined || node[childrenKey] === null) {
            node[childrenKey] = null;
        } else if (Array.isArray(node[childrenKey]) && node[childrenKey].length === 0) {
            node[childrenKey] = null;
        } else if (Array.isArray(node[childrenKey])) {
            node[childrenKey] = cleanTree(node[childrenKey], childrenKey);
        }
    }
    return result;
}

/**
 * 数组拍平并去重
 * @param { Array } arr 待处理的数组
 * @returns { Arrary } uniqueArr 结果数组
 */
function flattenAndUnique(arr) {
  let flatArr = arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(val) : acc.push(val), []);
  let uniqueArr = flatArr.filter((item, index) => flatArr.indexOf(item) === index);
  return uniqueArr
}

// 获取浏览器滚轮缩放比率
function getBrowserWheelZoomRatio() {
    let ratio = 1;
    let screen = window.screen;
    let ua = navigator.userAgent.toLowerCase();

    if (~ua.indexOf("msie")) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
        ratio = screen.deviceXDPI / screen.logicalXDPI;
      }
    } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
    }

    if (ratio) {
      ratio = ratio.toFixed(2)
      ratio = Math.round(ratio * 100);
    }

    return ratio
}

// 按名称排序
function arrSortByName (arr,name) {
	if(!arr || arr.length == 0 ){
		return [];
	}
	let resArr = [];
	resArr = arr.sort(function(a,b){
		return a[name].localeCompare(b[name], 'zh');
	})
	return resArr
}

export {
    formatRouterTree,
    generateRouter,
    filterTempInfo,
    filterTemplateBtnInfo,
    filterTempInfoType,
    listToTree,
    downloadFile,
    param2Obj,
    equivalentAssignment,
    integratingData,
    formatTime,
    formatTreeData,
    getSimpleId,
    isLayout,
    treeFilter,
    treeFind,
    treeForeach,
    treeFindPathAll,
    treeToList,
    unique,
    hasPermission,
    dataURLtoFile,
    removeClassSty,
    formatterXZ,
    removeTreeShapeDialog,
    arrObjDuplication,
    cleanTree,
    flattenAndUnique,
    getBrowserWheelZoomRatio,
    arrSortByName
}
