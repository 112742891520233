import { getUserThemeConfig as getData } from "@/services";
import themeOptions from '@/assets/data/theme_options.js';

async function getUserThemeConfig(userInfo) {
    if (userInfo.code === "5015" || userInfo.code === "20011") {
        return;
    };

    const USER_PERMISSION = 'themeSkin';
    let theme,
        menuId;

    let hasAuth = userInfo.data.userPermission.some(item => USER_PERMISSION === item);
    console.log(hasAuth, '是否含有权限')
    if (hasAuth) {
        menuId = '00010002';
    } else {
        return;
    }
    try{
        let getDetailTheme = {
            menuId: menuId,
            configType: 'themeSkin'
        };
        theme = await getData(getDetailTheme)
    } catch(e){
        theme = themeOptions[0];
    }
    
    return theme;
}


export {
    getUserThemeConfig
}