import store from '@/store/index.js';
import Vue from 'vue';
let vue = new Vue();

var tabPageHasAuth = function (tabList) {
    let newTabs = [],
        auth = store.state.permissions,
        appraisalAuthority = tabList.map((item) => {
            return  'vHasPermi' in item  && item.vHasPermi[0];
        });

    if (appraisalAuthority[0] === false && typeof appraisalAuthority[0] === 'boolean') return  tabList;

    for (let index = 0; index < appraisalAuthority.length; index++) {
        const element = appraisalAuthority[index];

        if (auth.indexOf(element) !== -1) {
            newTabs.push(tabList[index])
        }
    }

    newTabs.forEach((item, index) => {
        if (index === 0) {
            vue.$set(item, 'sel', true)
        } else {
            vue.$set(item, 'sel', false)
        }
    })

    return newTabs;
}

export {
    tabPageHasAuth
}
