import { BTN_MENUS, FIRST_PAGE, FIRST_PAGE_HOME, LEFT_SLIDER_HIDDEN_ROUTER } from '@/assets/data/router/unusual'
function processUserInfo (data) {
    /**
     * 用户基础信息
     *  -loginName 登录名
     *  -userImg 用户头像
     *  -token token
     *  -userName 用户名
     *  -userId 用户ID
     *  -schoolId 学校ID
     *
     * 用户权限信息
     *  -userPermission 按钮
     *  -menuList 路由
     *  -isSuper 是否是管理员
     *
     *
    */
    console.log(data,'处理用户信息data')
    const USER_BASIC_INFO = {
            loginName: '',
            userImg: '',
            token: '',
            userName: '',
            userId: '',
            schoolId: '',
        },
        USER_PRIVILEGE_INFO = {
            mixTopRouter: [],
            mixSideRouter: [],
            formatRouter: [],
            originRouter: [],
            segmentMenuList: [],
            segmentPermList: [],
            userPermission: [],
            isSuper: '',

        };
        const [ mixTopRouter, mixSideRouter, formatRouter] = getRouerInfo(data);

        Object.keys(data).forEach(item => {
            Object.keys(USER_BASIC_INFO).forEach(key => {
                if (item === key) {
                    USER_BASIC_INFO[key] = data[item];
                }
            })
        });

        USER_PRIVILEGE_INFO.userPermission = data.userPermission;
        USER_PRIVILEGE_INFO.isSuper = data.isSuper

        USER_PRIVILEGE_INFO.mixTopRouter = mixTopRouter;
        USER_PRIVILEGE_INFO.mixSideRouter = mixSideRouter;
        USER_PRIVILEGE_INFO.formatRouter = formatRouter;
        USER_PRIVILEGE_INFO.originRouter = data.menuList;
        USER_PRIVILEGE_INFO.segmentMenuList = data.segmentMenuList;
        USER_PRIVILEGE_INFO.segmentPermList = data.segmentPermList;

        console.log(USER_BASIC_INFO, '用户信息拼装完成')
        console.log(USER_PRIVILEGE_INFO, 'router格式化完成')

        return [ USER_BASIC_INFO, USER_PRIVILEGE_INFO ];
}

function getRouerInfo (data) {
    let mixTopRouter = [],
        mixSideRouter = [];

    let menuList = unusualRouter(data.btn ,data.menuList);
    let formatRouter = formatRouterTree(menuList);

        formatRouter.map(item => {
            let v = JSON.parse(JSON.stringify(item));

            if (item.children && item.children.length > 0) {
                delete v.children;
            };
            mixTopRouter.push(v);
        });

    mixTopRouter = deepAllRouter(mixTopRouter, formatRouter);
    mixSideRouter = Object.freeze(removeHiddenRouter(JSON.parse(JSON.stringify(formatRouter)), LEFT_SLIDER_HIDDEN_ROUTER));
    formatRouter = Object.freeze(formatRouter);
        console.log(mixSideRouter,'mixSideRouter')
    return [ mixTopRouter, mixSideRouter, formatRouter];
}

/**
 * 处理特殊路由
 * 按钮关联页面,检查按钮关联的页面是否存在，不存在则添加。
*/
function unusualRouter (btns, menuList) {
    for (let item of BTN_MENUS) {
        for (let btn of item.btn) {
            if (btns.includes(btn) && menuList.findIndex((e) => e.menuId == item.menu.menuId) == -1) {
                menuList.push(item.menu);
            }
        }
    }

    let userRouters = menuList.filter((i) => i.path);

    if (userRouters.length > 0) {
        let index1 = userRouters.findIndex((i) => i.menuId === "000000");
        if (index1 === -1) {
            userRouters.unshift(FIRST_PAGE);
        } else if (userRouters[index1].name === 'firstPage') {
            // 解决部分学校首页菜单错误的问题
            userRouters.splice(index1, 1, FIRST_PAGE);
        }

        let index2 = userRouters.findIndex((i) => i.menuId === "000100");
        if (index2 === -1) {
            userRouters.unshift(FIRST_PAGE_HOME);
        }
    }

    return userRouters;
}

function formatRouterTree(data) {
    let parents = data.filter(p => !p.parentId),
        children = data.filter(c => c.parentId);

    dataToTree(parents, children);

    function dataToTree(parents, children) {
        parents.map((p) => {
            children.map((c, i) => {
                if (c.parentId === p.menuId) {
                    let _c = JSON.parse(JSON.stringify(children));
                    _c.splice(i, 1);
                    dataToTree([c], _c);
                    if (p.children) {
                        p.children.push(c);
                    } else {
                        p.children = [c];
                    }
                }
            })
        })
    }

    return parents;
}

function deepAllRouter (tar, ori) {
    ori.map(oItem => {
        tar.map(tItem => {
            if (oItem.menuId == tItem.menuId) {
                let children = deepChild([],oItem.children)
                tItem['children'] = children && children.length > 0 ? children : [];
            }
        })
    })

    return tar;
}

function deepChild (ar, data = []) {
    data.map(item => {
        ar.push(item);
        if (item.children && item.children.length > 0) {
            deepChild (ar, item.children)
        }
    });
    return ar;
}

// 去除左侧不显示的路由
function removeHiddenRouter (formatRouter, hiddenRouter) {
    function removeHiddenItems(routerArray) {
        let indexToRemove = [];
        routerArray.forEach((item, index) => {
        const hiddenIndex = hiddenRouter.findIndex(subItem => subItem.name === item.name);
            if (hiddenIndex !== -1) {
                indexToRemove.push(index);
            }

            if (item.children && item.children.length > 0) {
                removeHiddenItems(item.children);
            }
        });

        indexToRemove.sort((a, b) => b - a).forEach(index => {
            routerArray.splice(index, 1);
        });
      }
      removeHiddenItems(formatRouter);
      return formatRouter;
}

export default processUserInfo;
