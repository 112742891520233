const CryptoJS = require('crypto-js')// 引用AES源码js

const key = CryptoJS.enc.Utf8.parse('xinKaoForTodoKey')// 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('xinKaoForTodo_Iv')// 十六位十六进制数作为密钥偏移量

// 加密方法
export function Encrypt(word) {
  let _word = CryptoJS.enc.Utf8.parse(word)
  let encrypted = CryptoJS.AES.encrypt(_word, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  return encrypted.toString()
}

// 解密
export function Decrypt(word) {
  let decrypted = CryptoJS.AES.decrypt(word, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  let decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString()
}
