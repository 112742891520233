const regular = {
    // 正整数验证
    positiveInteger: /(^[1-9]\d*$)/,
    // 身份证号码验证
    IDNumber: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    // 手机号码验证
    phoneNumber: /^1[0-9][0-9]{9}$/,
    // 大于0的数
    greaterThan: /^$|^\s*[1-9]\d*\s*$/,
    // 大于0的正整数
    greaterThanPositiveInteger: /^\+?[1-9]\d*$/,
    // 邮箱验证
    email: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
    // 密码验证 密码必须是包含大小写字母和数字的组合，不能使用特殊字符，长度在8 - 10之间
    password: /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$/,
    // 密码验证 密码包含数字 字母6到10位
    password1: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/,
    // 密码验证 密码必须包含大小写字母，特殊符号(@$!%*?)和数字且长度不小于8位
    password2: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?.&])[A-Za-z\d$@$!%*?.&]{8,}/,
    // 字符串只能是中文
    stringChinese: /^[\\u4e00-\\u9fa5]{0,}$/,
    // 由数字、 26 个英文字母或下划线组成的字符串
    stringRole: /^\\w+$/,
    // 校验日期
    inspectDate: /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/,
    // 校验金额
    inspectMoney: /^[0-9]+(.[0-9]{2})?$/,
    // 去除()括号和括号中的内容
    removeBrackets: /\([^\)]*\)/g
}

export function regularExpression(type, param) {
    if (type === 'removeBrackets') {
        return param.replace(/\([^\)]*\)/g, '')
    }
    return regular[type].test(param)
}

export default regular