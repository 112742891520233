/**
 * 静态资源
 * filePreview 文件预览
 */


function filePreview () {
    const FILE_PREVIEW = 'https://view.xdocin.com/view?src=';

    function openNewPage (filePath) {
        let incomplete = FILE_PREVIEW + decodeURIComponent(filePath)
        window.open(incomplete)
    }

    function getFilePreviewPath () {
        return FILE_PREVIEW
    }

    return {
        openNewPage,
        getFilePreviewPath
    }
}


export {
    filePreview
}
